import React from 'react'

const UploadLogoHint: React.FC = () => {
  return (
    <div className="pop-in">
      <p>
        Please upload a high-resolution PNG copy of your firm's company logo
        with a transparent background.
      </p>
    </div>
  )
}

export default UploadLogoHint
