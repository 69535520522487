import './CompanyServiceDetailsRow.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faRemove } from '@rq-ratings/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import * as yup from 'yup'

import useCurrentCompanyOrFail from '../../../../../../hooks/useCurrentCompanyOrFail'
import serviceService from '../../../../../../lib/services/serviceService'
import { ServiceAreaItem } from '../../../../../../types/responses/common-data'
import ActionButton from '../../../../../misc/ActionButton'
import SelectServiceAreaField from './fields/SelectServiceAreaField'
import SelectServiceTypeField from './fields/SelectServiceTypeField'

export interface ServiceFormValues {
  id: number | undefined
  company: IRI
  serviceArea: ServiceAreaItem | undefined
  isStandaloneService: boolean
  notes: string
  serviceType: IRI | undefined
}

interface Props {
  service: ServiceFormValues
  rowNumber: number
  onSave: (values: ServiceFormValues) => void
  onDelete: () => void
  isEditingByDefault: boolean
}

const CompanyServiceDetailsRow: React.FC<Props> = ({
  service,
  rowNumber,
  onSave,
  onDelete,
  isEditingByDefault,
}) => {
  const currentCompany = useCurrentCompanyOrFail()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(isEditingByDefault)

  const addOrUpdateServiceMutation = useMutation({
    mutationFn: async (service: ServiceFormValues) => {
      if (service.serviceArea === undefined) {
        return
      }

      // POST new services
      if (service.id === undefined) {
        return service.serviceArea?.isFcaServiceArea === true
          ? serviceService.postFcaService(
              currentCompany.id,
              service.serviceArea.id,
              service.isStandaloneService,
              service.notes,
              service.serviceType as IRI,
            )
          : serviceService.postService(
              currentCompany.id,
              service.serviceArea.id,
              service.isStandaloneService,
              service.notes,
            )
      }

      // PATCH existing services
      return service.serviceArea?.isFcaServiceArea === true
        ? serviceService.patchFcaService(
            service.id,
            currentCompany.id,
            service.serviceArea.id,
            service.isStandaloneService,
            service.notes,
            service.serviceType as IRI,
          )
        : serviceService.patchService(
            service.id,
            currentCompany.id,
            service.serviceArea.id,
            service.isStandaloneService,
            service.notes,
          )
    },
    onSuccess: () => {
      onSave(service)
    },
  })

  const deleteServiceMutation = useMutation({
    mutationFn: async (serviceId: number) => {
      return serviceService.deleteService(serviceId)
    },
    onSuccess: () => {
      onDelete()
    },
    onError: () => {
      setIsDeleting(false)
    },
  })

  return (
    <div
      className={`company-service-details-row${isDeleting ? ' deleting' : ''} text-start`}
    >
      {!isEditing && (
        <button
          className="card border p-3 m-0 d-flex flex-row gap-3 text-start"
          onClick={() => setIsEditing(true)}
          title="Edit"
        >
          <div className="flex-grow-1">
            <h3 className="m-0">{service.serviceArea?.name}</h3>

            <p className="m-0">
              {service.isStandaloneService
                ? 'Offered standalone'
                : 'Not offered standalone'}
              {service.serviceType === undefined
                ? ''
                : ` | ${service.serviceType}`}
              {service.notes ? (
                <>
                  {' '}
                  | <em>{service.notes}</em>
                </>
              ) : (
                ''
              )}
            </p>
          </div>

          {/* Not a button because we're already inside a button that does the same thing,
          this is just in case it's not clear to the user that they can just click the card */}
          <div
            className="btn btn-outline-primary align-self-start align-self-center text-nowrap"
            onClick={() => setIsEditing(true)}
          >
            <FontAwesomeIcon icon={faPencil} className="me-2" />
            Edit
          </div>
        </button>
      )}

      {isEditing && (
        <Formik
          initialValues={service}
          enableReinitialize
          validateOnChange
          validationSchema={yup.object().shape({
            id: yup.number().min(0).nullable(),
            company: yup.string().required('Required'),
            serviceArea: yup.object().shape({
              id: yup.number().min(0).required(),
            }),
            isStandaloneService: yup.bool().required(),
            notes: yup.string().nullable(),
            serviceType: yup.string().when('serviceArea', {
              is: (serviceArea: ServiceAreaItem): boolean => {
                return serviceArea.isFcaServiceArea
              },
              then: () => yup.string().required('Required'),
              otherwise: undefined,
            }),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            await addOrUpdateServiceMutation.mutateAsync(values)

            if (onSave !== undefined) {
              onSave(values)
            }

            setSubmitting(false)
            setIsEditing(false)
          }}
        >
          {({ errors, touched, isSubmitting, submitForm, values }) => {
            return (
              <>
                <Form
                  className={`
                  card border p-3 m-0 w-100
                  ${service.id === undefined ? 'pop-in' : ''}
                `}
                >
                  <div className="row">
                    <div className="col d-flex flex-column gap-2">
                      {/* Service area */}
                      <div>
                        <label
                          className="form-label"
                          htmlFor={`company-service-details-is-standalone-service-${rowNumber}`}
                        >
                          Service
                        </label>

                        <Field
                          component={SelectServiceAreaField}
                          name="serviceArea"
                          className="flex-grow-1"
                        />

                        {errors.serviceArea && touched.serviceArea ? (
                          <div>{errors.serviceArea}</div>
                        ) : null}
                      </div>

                      {/* Is Standalone */}
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor={`company-service-details-is-standalone-service-${rowNumber}`}
                        >
                          Offered as standalone service?
                        </label>

                        <Field
                          className="form-check-input"
                          name="isStandaloneService"
                          type="checkbox"
                          id={`company-service-details-is-standalone-service-${rowNumber}`}
                        />

                        {errors.isStandaloneService &&
                        touched.isStandaloneService ? (
                          <div>{errors.isStandaloneService}</div>
                        ) : null}
                      </div>
                    </div>

                    {/* Service type (FCA only) */}
                    {values.serviceArea &&
                      values.serviceArea.isFcaServiceArea && (
                        <div className="col">
                          <label
                            className="form-label"
                            htmlFor={`company-service-details-is-standalone-service-${rowNumber}`}
                          >
                            Independence status
                          </label>

                          <Field
                            component={SelectServiceTypeField}
                            name="serviceType"
                            className="flex-grow-1"
                          />

                          {errors.serviceType && touched.serviceType ? (
                            <div>{errors.serviceType}</div>
                          ) : null}
                        </div>
                      )}

                    {/* Notes */}
                    <div className="col">
                      <label
                        className="form-label"
                        htmlFor={`company-service-details-notes-${rowNumber}`}
                      >
                        Notes
                      </label>

                      <Field
                        className="form-control"
                        name="notes"
                        component="textarea"
                        rows={2}
                        id={`company-service-details-notes-${rowNumber}`}
                      />

                      {errors.notes && touched.notes ? (
                        <div>{errors.notes}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="d-flex gap-3 justify-content-end align-items-center mt-3">
                    <ActionButton
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => {
                        setIsDeleting(true)

                        if (service.id === undefined) {
                          // No ID means this isn't saved yet anyway, so no mutation.
                          // Do it in 300ms to allow a nice transition.
                          setTimeout(() => onDelete(), 300)
                        } else {
                          deleteServiceMutation.mutate(service.id)
                        }
                      }}
                      isProcessing={isDeleting}
                    >
                      <FontAwesomeIcon icon={faRemove} className="me-2" />
                      Remove
                    </ActionButton>

                    <ActionButton
                      isProcessing={isSubmitting}
                      isProcessingText="Saving"
                      showSpinner={isSubmitting}
                      variant="success"
                      onClick={() => {
                        if (!isSubmitting) {
                          submitForm()
                        }
                      }}
                    >
                      Save
                      {isSubmitting && <Spinner className="ms-2" />}
                    </ActionButton>
                  </div>
                </Form>
              </>
            )
          }}
        </Formik>
      )}

      {isDeleting && (
        <div className="overlay">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default CompanyServiceDetailsRow
