import queryString from 'query-string'
import React from 'react'

interface Props {
  className?: string
  videoUrl: string
  height: number
  autoplay?: boolean
  style?: React.CSSProperties
}

const VideoEmbed: React.FC<Props> = ({
  className,
  videoUrl,
  height,
  autoplay = false,
  style,
}) => {
  const iframeParams = {
    h: '3f6082791d',
    title: 0,
    byline: 0,
    portrait: 0,
    sidedock: 0,
    transparent: 0,
    autoplay: autoplay ? 1 : 0,
  }

  return (
    <iframe
      title="RQ-video"
      className={className}
      src={`${videoUrl}?${queryString.stringify(iframeParams)}`}
      width="100%"
      height={height}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      style={style}
    />
  )
}

export default VideoEmbed
