import React from 'react'

const MeetingLinkHint: React.FC = () => {
  return (
    <div className="pop-in">
      <p>
        Please provide a link to a calendar that professional referral partners
        can use to book a call with you.
      </p>

      <p>
        E.g., services like Calendly. If you don't have an account already,
        create one for free at{' '}
        <a href="https://calendly.com" target="_blank" rel="noreferrer">
          calendly.com
        </a>
        .
      </p>
    </div>
  )
}

export default MeetingLinkHint
