import React from 'react'

const CompanyServiceDetailsHint: React.FC = () => {
  return (
    <div className="pop-in">
      <p>Tell us a little about the services you offer.</p>
    </div>
  )
}

export default CompanyServiceDetailsHint
