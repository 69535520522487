import { NoContentResponse } from '../../types/api'
import { CompanyAddressCollectionItem } from '../../types/responses/companies'
import apiService from './apiService'

interface AddressRequestParams {
  company: IRI
  street: string
  county: string
  city: string
  postcode: string
  isMainAddress: boolean
}

class AddressService {
  private endpoint = '/v1/addresses'

  public async postAddress(params: AddressRequestParams) {
    const response = await apiService.post(this.endpoint, params)

    return response.data
  }

  public async patchAddress(addressId: number, params: AddressRequestParams) {
    const response = await apiService.patch(
      `${this.endpoint}/${addressId}`,
      params,
    )

    return response.data
  }

  public async deleteAddress(addressId: number) {
    const response = await apiService.delete<NoContentResponse>(
      `${this.endpoint}/${addressId}`,
    )

    return response.data
  }

  public async getCurrentCompanyAddresses(): Promise<CompanyAddressCollectionItem> {
    const response = await apiService.get(this.endpoint)

    return response.data['hydra:member']
  }
}

const addressService = new AddressService()

export default addressService
