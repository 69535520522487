import { CompassFeedbackType } from '../../../../types/responses/compass'
import {
  CompassBooleanAnswer,
  CompassFeedbackCategoryKey,
  CompassFeedbackCategoryName,
  CompassParams,
  CompassQuestionFor,
  CompassStep,
} from './types'

export const PARTNER_OR_SPOUSE_TERM = {
  partner: 'partner',
  spouse: 'spouse',
}

export const COMPASS_QUESTION_FOR: Record<
  CompassQuestionFor,
  CompassQuestionFor
> = {
  joint: 'joint',
  user: 'user',
  partner: 'partner',
}

export const BUSINESS_ASSETS_TERM = {
  businessShareholdings: 'business shareholding(s)',
  partnershipInterests: 'partnership interest(s)',
}

export const FEEDBACK_TYPE = {
  action: 'Action',
  context: 'Context',
  tip: 'Tip',
  didYouKnow: 'Did you know',
  somethingToThinkAbout: 'Something to think about',
} satisfies Record<string, CompassFeedbackType>

export const COMPASS_BOOLEAN_ANSWER: Record<
  CompassBooleanAnswer,
  CompassBooleanAnswer
> = {
  Yes: 'Yes',
  No: 'No',
  "I don't know": "I don't know",
}

export const COMPASS_STEPS: Record<CompassStep, CompassStep> = {
  welcome: 'welcome',
  aboutCompass: 'aboutCompass',
  requiredDetails: 'requiredDetails',
  howToUse: 'howToUse',
  verifyIdentity: 'verifyIdentity',
  usageType: 'usageType',
  inviteClients: 'inviteClients',
  clientDetails: 'clientDetails',
  verifyEmail: 'verifyEmail',
  personalAssets: 'personalAssets',
  businessAssets: 'businessAssets',
  liabilities: 'liabilities',
  income: 'income',
  interests: 'interests',
  results: 'results',
}

export const COMPASS_INFO_STEPS: Array<CompassStep> = [
  COMPASS_STEPS.welcome,
  COMPASS_STEPS.aboutCompass,
  COMPASS_STEPS.requiredDetails,
  COMPASS_STEPS.howToUse,
  COMPASS_STEPS.verifyIdentity,
]

export const COMPASS_INPUT_STEPS: Array<CompassStep> = [
  COMPASS_STEPS.clientDetails,
  COMPASS_STEPS.verifyEmail,
  COMPASS_STEPS.personalAssets,
  COMPASS_STEPS.businessAssets,
  COMPASS_STEPS.liabilities,
  COMPASS_STEPS.income,
  COMPASS_STEPS.interests,
  COMPASS_STEPS.results,
]

export const COMPASS_FORM_STEPS: Array<CompassStep> = [
  COMPASS_STEPS.clientDetails,
  COMPASS_STEPS.personalAssets,
  COMPASS_STEPS.businessAssets,
  COMPASS_STEPS.liabilities,
  COMPASS_STEPS.income,
  COMPASS_STEPS.interests,
]

export type CompassSteps = Array<keyof typeof COMPASS_STEPS>

export const COMPASS_STEP_NAMES: CompassSteps = Object.keys(
  COMPASS_STEPS,
) as CompassSteps

export const COMPASS_API_ERRORS = {
  required: 'Field required',
  invalidFloat: 'value is not a valid float',
  invalidInteger: 'value is not a valid integer',
}

export const DEFAULT_FINANCIAL_INDEPENDENCE_AGE = 65
export const EXPECTED_AGE_OF_DEATH = 95

export const COMPASS_PARAMS: Record<keyof CompassParams, keyof CompassParams> =
  {
    clientId: 'clientId',
    referralId: 'referralId',
    step: 'step',
    token: 'token',
    inviteClientsTab: 'inviteClientsTab',
    compassReportId: 'compassReportId',
    willShareWithClient: 'willShareWithClient',
    returnUrl: 'returnUrl',
    withCompany: 'withCompany',
    companyReferralCode: 'companyReferralCode',
    backUrl: 'backUrl',
  }

export const PROFESSIONAL_SITUATION_OPTIONS = {
  businessOwner: 'Business owner (Major / Minor Shareholder)',
  partner: 'Partner (Major / Minor Partner)',
}

export const FEEDBACK_CATEGORY: Record<
  CompassFeedbackCategoryKey,
  CompassFeedbackCategoryName
> = {
  Business: 'Business',
  Cash: 'Cash',
  Expenditure: 'Expenditure',
  FinancialLifePlanning: 'Financial Life Planning',
  FinancialProtection: 'Financial Protection',
  Income: 'Income',
  Investments: 'Investments',
  Pensions: 'Pensions',
  PropertyMortgage: 'Property / Mortgage',
  Tax: 'Tax',
}

export const FEEDBACK_CATEGORY_KEY: Record<
  CompassFeedbackCategoryKey,
  CompassFeedbackCategoryKey
> = {
  Business: 'Business',
  Cash: 'Cash',
  Expenditure: 'Expenditure',
  FinancialLifePlanning: 'FinancialLifePlanning',
  FinancialProtection: 'FinancialProtection',
  Income: 'Income',
  Investments: 'Investments',
  Pensions: 'Pensions',
  PropertyMortgage: 'PropertyMortgage',
  Tax: 'Tax',
}

export const FEEDBACK_CATEGORY_KEYS = Object.values(FEEDBACK_CATEGORY_KEY)

export const NONE_OF_THE_ABOVE_OPTION = 'I have none of the above in place'

export const WILL_FAMILY_PROTECTION_OPTION =
  'A Will(s) that reflects your current / anticipated circumstances and wishes'

export const NOT_EMPLOYED_OPTION = 'Not employed'

export const NOT_APPLICABLE_OPTION = 'N/A'

export const OTHER_GOAL_CATEGORY_OPTION = 'other'

export const COMPASS_HEALTH_LABELS = {
  financialHealth: 'Financial health',
  onTrackResilience: 'Resilient & on track',
  organisedOptimised: 'Organised & optimised',
}
