import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@rq-ratings/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import { Field, Formik } from 'formik'
import React from 'react'
import { Alert, Form } from 'react-bootstrap'
import * as yup from 'yup'

import useAppDispatch from '../../../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import useNotyf from '../../../../../hooks/useNotyf'
import companyService from '../../../../../lib/services/companyService'
import { updateCurrentCompany } from '../../../../../redux/slices/session'
import { CompanyScheduleCallUrlsItem } from '../../../../../types/responses/companies'
import ActionButton from '../../../../misc/ActionButton'

interface UpdateScheduleCallUrlParams {
  scheduleCompanyCallUrl: string
  scheduleClientCallUrl: string
}

const MeetingLink: React.FC = () => {
  const company = useCurrentCompanyOrFail()

  const dispatch = useAppDispatch()

  const notyf = useNotyf()

  const mutation = useMutation({
    mutationFn: async (
      params: UpdateScheduleCallUrlParams,
    ): Promise<CompanyScheduleCallUrlsItem> => {
      return companyService.updateScheduleCallUrl(company.id, params)
    },
    onSuccess: async (data: CompanyScheduleCallUrlsItem) => {
      dispatch(updateCurrentCompany(data))
    },
    onError: async () => {
      notyf.error('Failed to save URL. Please try again.')
    },
  })

  return (
    <div className="pop-in h-100 d-flex flex-column align-items-stretch text-start">
      <h2 className="h1 text-center">Your meeting links</h2>

      <Formik
        initialValues={{
          scheduleCompanyCallUrl: company.scheduleCompanyCallUrl ?? '',
          scheduleClientCallUrl: company.scheduleClientCallUrl ?? '',
        }}
        enableReinitialize
        validateOnChange
        validationSchema={yup.object().shape({
          scheduleCompanyCallUrl: yup
            .string()
            .url('Must be a valid URL')
            .nullable(),
          scheduleClientCallUrl: yup
            .string()
            .url('Must be a valid URL')
            .nullable(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await mutation.mutateAsync(values)

          setSubmitting(false)
        }}
      >
        {({ isSubmitting, submitForm, isValid, errors, touched }) => (
          <>
            <Form className="flex-grow-1 d-flex flex-column gap-5 align-items-stretch justify-content-center w-100">
              <div>
                <label className="h4">Link for meetings with clients</label>
                <Field
                  autoFocus
                  name="scheduleClientCallUrl"
                  className="form-control"
                />
                {errors.scheduleClientCallUrl &&
                touched.scheduleClientCallUrl ? (
                  <div className="text-danger">
                    {errors.scheduleClientCallUrl}
                  </div>
                ) : null}
              </div>

              <div>
                <label className="h4">
                  Link for meetings with other professionals
                </label>
                <Field name="scheduleCompanyCallUrl" className="form-control" />
                {errors.scheduleCompanyCallUrl &&
                touched.scheduleCompanyCallUrl ? (
                  <div className="text-danger">
                    {errors.scheduleCompanyCallUrl}
                  </div>
                ) : null}
              </div>

              <ActionButton
                className="align-self-center"
                variant="success"
                size="lg"
                isProcessing={isSubmitting}
                isProcessingText="Saving"
                onClick={submitForm}
                disabled={!isValid}
              >
                Save
              </ActionButton>
            </Form>

            {mutation.isSuccess && (
              <Alert variant="success" className="p-2 align-self-center">
                <p className="fs-3 mb-0">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-success"
                  />{' '}
                  Saved
                </p>
              </Alert>
            )}
          </>
        )}
      </Formik>
    </div>
  )
}

export default MeetingLink
