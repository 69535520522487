import { useMutation } from '@tanstack/react-query'
import React from 'react'

import useAppDispatch from '../../../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import companyService from '../../../../../lib/services/companyService'
import { updateCurrentCompany } from '../../../../../redux/slices/session'
import { CompanyLogoItem } from '../../../../../types/responses/companies'
import UploadFile from '../../components/UploadFile/UploadFile'

const UploadLogo: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentCompany = useCurrentCompanyOrFail()

  const uploadLogoMutation = useMutation({
    mutationFn: async (file: File): Promise<CompanyLogoItem> => {
      return companyService.updateLogo(currentCompany.id, { logoFile: file })
    },
    onSuccess: async (data: CompanyLogoItem) => {
      dispatch(updateCurrentCompany(data))
    },
  })

  return (
    <div className="h-100 d-flex flex-column pop-in">
      <h2 className="h1">Upload your firm's logo</h2>

      <div className="d-flex flex-grow-1 flex-column align-items-stretch justify-content-center w-100">
        <UploadFile
          uploadMutation={uploadLogoMutation}
          acceptableFileTypes={['image/png', 'image/jpeg', 'image/gif']}
          previewType="image"
          currentFileUrl={currentCompany.logoUrl}
          incorrectFileTypeMessage="Please choose a PNG image with a transparent background."
          currentFileExistsButIsUnavailable={false}
        />
      </div>
    </div>
  )
}

export default UploadLogo
