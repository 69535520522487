import {
  BusinessAssetsInputFields,
  ShareInputFields,
} from '../../../components/flows/CompassFlow/steps/9_BusinessAssets/constants'
import {
  BUSINESS_ASSETS_TERM,
  PROFESSIONAL_SITUATION_OPTIONS,
} from '../../../components/flows/CompassFlow/utils/constants'
import { willSellBusiness } from '../../../components/flows/CompassFlow/utils/helpers'
import {
  BusinessAssetsInput,
  CompassInput,
  PropertyInput,
  ShareInput,
} from '../../../components/flows/CompassFlow/utils/types'
import { PaginationFilters } from '../../../types/api'
import {
  CompassSendVerificationCodeRequest,
  CompassSendVerificationCodeResponse,
  CompassVerifyIdentityByCodeRequest,
  CompassVerifyIdentityRequest,
  CreateBulkCompassReportsRequest,
  CreateCompassReportRequest,
  GetCompassReportQueryParams,
  GetCompassReportsWithActionsParams,
  GetCompassReportsWithBusinessSaleParams,
  GetCompassReportsWithMortgageParams,
  GetCompassReportsWithPensionDetailsParams,
  GetCompassReportsWithProtectionDetailsParams,
  GetCompassReportsWithWillDetailsParams,
  RequestCompassUpdatesRequest,
  SaveCompassReportRequest,
  ShareCompassReportWithClientRequest,
} from '../../../types/requests/compass-reports'
import {
  CompassReportAdviserCollection,
  CompassReportCollection,
  CompassReportCollectionItem,
  CompassReportCompanyStatsItem,
  CompassReportItem,
  CompassReportWithActionsCollection,
  CompassReportWithBusinessSaleCollection,
  CompassReportWithMortgageCollection,
  CompassReportWithPensionDetailsCollection,
  CompassReportWithProtectionDetailsCollection,
  CompassReportWithWillDetailsCollection,
  CreateBulkCompassReportsResponseItem,
  DuplicatedCompassReportItem,
  LatestClientCompassReportItem,
  LatestCompassReportItem,
  LatestCompletedCompassReportItem,
  LatestInProgressCompassReportItem,
} from '../../../types/responses/compass-reports'
import { TokenResponse } from '../../../types/responses/tokens'
import { COMPASS_REPORT_STATUS, ORDER_DIRECTION } from '../../constants'
import { formatMoneyCompact, getOrFail } from '../../helpers/helperFunctions'
import apiService from '../apiService'
import compassClientDetailsService from './compassClientDetailsService'

class CompassReportService {
  private endpoint = '/v1/compass-reports'

  async getCompanyStats(): Promise<CompassReportCompanyStatsItem> {
    const response = await apiService.get(`${this.endpoint}/company-stats`)

    return response.data
  }

  async getCompletedCompassReportsForClient(
    clientId: number,
  ): Promise<CompassReportCollection> {
    const response = await apiService.get(this.endpoint, {
      params: {
        status: COMPASS_REPORT_STATUS.COMPLETE,
        'client.id': clientId,
        'order[createdAt]': ORDER_DIRECTION.desc,
      },
    })

    return response.data
  }

  async getCompassReport(
    token: string,
    params?: GetCompassReportQueryParams,
  ): Promise<CompassReportItem> {
    const response = await apiService.get(`${this.endpoint}/${token}`, {
      params,
    })

    return response.data
  }

  async getLatestCompletedCompassReportForClient(
    clientId: number,
  ): Promise<CompassReportCollectionItem | null> {
    const paginationFilters: PaginationFilters = { page: 1, pageSize: 1 }

    const response = await apiService.get<CompassReportCollection>(
      this.endpoint,
      {
        params: {
          ...paginationFilters,
          'client.id': clientId,
          status: COMPASS_REPORT_STATUS.COMPLETE,
        },
      },
    )

    return response.data['hydra:member'][0] ?? null
  }

  async getLatestCompassReport(): Promise<LatestCompassReportItem> {
    const response = await apiService.get(`${this.endpoint}/latest-report`)

    return response.data
  }

  async getLatestClientCompassReport(
    clientId: number,
  ): Promise<LatestClientCompassReportItem> {
    const response = await apiService.get(
      `/v1/clients/${clientId}/latest-report`,
    )

    return response.data
  }

  async getLatestCompletedCompassReport(): Promise<LatestCompletedCompassReportItem> {
    const response = await apiService.get(
      `${this.endpoint}/latest-completed-report`,
    )

    return response.data
  }

  async saveCompassReport(
    request: SaveCompassReportRequest,
  ): Promise<CompassReportItem> {
    const response = await apiService.post<CompassReportItem>(this.endpoint, {
      ...request,
      status: COMPASS_REPORT_STATUS.IN_PROGRESS,
    })

    return response.data
  }

  async saveCompassReportPrivacy(
    token: string,
    isPrivate: boolean,
  ): Promise<{ token: string; isPrivate: boolean }> {
    const response = await apiService.patch<{
      token: string
      isPrivate: boolean
    }>(`${this.endpoint}/${token}/privacy`, { isPrivate })

    return response.data
  }

  async createCompassReport(
    request: CreateCompassReportRequest,
  ): Promise<CompassReportItem> {
    const response = await apiService.post<CompassReportItem>(this.endpoint, {
      ...request,
      status: COMPASS_REPORT_STATUS.COMPLETE,
    })

    return response.data
  }

  async shareReportWithClient(request: ShareCompassReportWithClientRequest) {
    const response = await apiService.post(
      `${this.endpoint}/share-with-client`,
      request,
    )

    return response.data
  }

  async createCompassReportFromCompanyReferralCode(
    companyReferralCode: string,
  ) {
    const response = await apiService.post(
      `${this.endpoint}/create-from-company-code`,
      { companyReferralCode },
    )

    return response.data
  }

  async duplicateCompassReport(
    token: string,
  ): Promise<DuplicatedCompassReportItem> {
    const response = await apiService.post(
      `${this.endpoint}/${token}/duplicate`,
      {},
    )

    return response.data
  }

  async downloadCompassReport(compassReportToken: string) {
    const response = await apiService.post<{ downloadUrl: string }>(
      `${this.endpoint}/${compassReportToken}/download`,
      { token: compassReportToken },
    )

    const downloadUrl = response.data.downloadUrl

    const link = document.createElement('a')
    link.href = downloadUrl
    link.click()
  }

  async createBulkCompassReportInvites(
    request: CreateBulkCompassReportsRequest,
  ): Promise<CreateBulkCompassReportsResponseItem> {
    const response = await apiService.post(
      `${this.endpoint}/bulk-invite`,
      request,
    )

    return response.data
  }

  async sendVerificationCode(
    request: CompassSendVerificationCodeRequest,
  ): Promise<CompassSendVerificationCodeResponse> {
    const response = await apiService.post(
      `${this.endpoint}/${request.token}/send-verification-code`,
      request,
    )

    return response.data
  }

  async verifyIdentity(
    request: CompassVerifyIdentityRequest,
  ): Promise<TokenResponse> {
    const response = await apiService.post(
      `${this.endpoint}/${request.token}/verify-identity`,
      request,
    )

    return response.data
  }

  async verifyIdentityByCode(
    request: CompassVerifyIdentityByCodeRequest,
  ): Promise<TokenResponse> {
    const response = await apiService.post(
      `${this.endpoint}/${request.token}/verify-identity-by-code`,
      request,
    )

    return response.data
  }

  async getLatestInProgressClientCompassReport(
    compassReportToken: string,
  ): Promise<LatestInProgressCompassReportItem> {
    const response = await apiService.get(
      `${this.endpoint}/${compassReportToken}/latest-in-progress-client-report`,
    )

    return response.data
  }

  async requestUpdates(
    request: RequestCompassUpdatesRequest,
  ): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/request-updates`,
      request,
    )

    return response.data
  }

  async getCompassReportAdvisers(
    token: string,
  ): Promise<CompassReportAdviserCollection> {
    const response = await apiService.get(`${this.endpoint}/${token}/advisers`)

    return response.data
  }

  async getCompassReportsWithActions(
    params: GetCompassReportsWithActionsParams,
  ): Promise<CompassReportWithActionsCollection> {
    const response = await apiService.get(`${this.endpoint}/with-actions`, {
      params,
    })

    return response.data
  }

  async getCompassReportsWithBusinessSale(
    params: GetCompassReportsWithBusinessSaleParams,
  ): Promise<CompassReportWithBusinessSaleCollection> {
    const response = await apiService.get(
      `${this.endpoint}/with-business-sale`,
      {
        params,
      },
    )

    return response.data
  }

  async getCompassReportsWithMortgage(
    params: GetCompassReportsWithMortgageParams,
  ): Promise<CompassReportWithMortgageCollection> {
    const response = await apiService.get(`${this.endpoint}/with-mortgage`, {
      params,
    })

    return response.data
  }

  async getCompassReportsWithWillDetails(
    params: GetCompassReportsWithWillDetailsParams,
  ): Promise<CompassReportWithWillDetailsCollection> {
    const response = await apiService.get(
      `${this.endpoint}/with-will-details`,
      {
        params,
      },
    )

    return response.data
  }

  async getCompassReportsWithProtectionDetails(
    params: GetCompassReportsWithProtectionDetailsParams,
  ): Promise<CompassReportWithProtectionDetailsCollection> {
    const response = await apiService.get(
      `${this.endpoint}/with-protection-details`,
      { params },
    )

    return response.data
  }

  async getCompassReportsWithPensionDetails(
    params: GetCompassReportsWithPensionDetailsParams,
  ): Promise<CompassReportWithPensionDetailsCollection> {
    const response = await apiService.get(
      `${this.endpoint}/with-pension-details`,
      { params },
    )

    return response.data
  }

  getCompaniesNames(businessAssets?: BusinessAssetsInput): string[] {
    const shares = businessAssets?.shares || []

    return shares.map((share) => share.company_name || '').filter(Boolean)
  }

  getBusinessAssetsTerm(professionalSituation?: string[]): string {
    const hasSelectedBusinessShareholdingOption = (
      professionalSituation || []
    ).includes(PROFESSIONAL_SITUATION_OPTIONS.businessOwner)

    return hasSelectedBusinessShareholdingOption
      ? BUSINESS_ASSETS_TERM.businessShareholdings
      : BUSINESS_ASSETS_TERM.partnershipInterests
  }

  getBusinessExitSummary({
    share,
    clientName,
    businessAssetsTerm,
  }: {
    share: ShareInput
    clientName?: string
    businessAssetsTerm: string
  }): string {
    const companyName = share.company_name
    const companyNameText = companyName ? ` in ${companyName} ` : ' '
    const sellValue = getOrFail(share.expected_value)
    const confidenceText = this.getBusinessExitConfidenceText(share.confidence)

    if (clientName) {
      return [
        `${clientName} is planning to sell some or all of their ${businessAssetsTerm} ${companyNameText}in ${share.year}.`,
        `${clientName} is ${confidenceText} that they will achieve a valuation of ${formatMoneyCompact(
          sellValue,
        )}.`,
      ].join(' ')
    }

    return [
      `You are planning to sell some or all of your ${businessAssetsTerm}
          ${companyNameText}in ${share.year}.`,
      `You are ${confidenceText} that you will achieve a valuation of ${formatMoneyCompact(
        sellValue,
      )}. `,
    ].join(' ')
  }

  shouldShowBusinessSaleNote(compassInput: CompassInput): boolean {
    return (
      compassClientDetailsService.isBusinessOwnerOrPartner(
        compassInput.aboutYourClient?.user_professional_situation,
      ) && willSellBusiness(compassInput?.businessAssets)
    )
  }

  getBusinessExitConfidenceText(confidence?: number) {
    if (!confidence) {
      throw new Error(
        `Expected ${BusinessAssetsInputFields.shares}[${ShareInputFields.confidence}] to be set`,
      )
    }

    function getConfidenceTerm(confidence: number) {
      if (confidence >= 7) {
        return 'very confident'
      }

      if (confidence >= 4) {
        return 'moderately confident'
      }

      return 'not very confident'
    }

    const confidenceTerm = getConfidenceTerm(confidence)

    return `${confidenceTerm} (${confidence} out of 10)`
  }

  // Note: This wording is shown next to the outlook chart in the Compass PDF
  // report where space is limited. Please check that any wording changes don't
  // break the PDF page.
  getBusinessSaleNote() {
    return (
      <>
        Please note: your anticipated business exit(s) / sale(s) value(s) has
        had a sensible discount factor applied, which takes into account your
        confidence level and proximity to exit / sale.
      </>
    )
  }

  getMortgageTermEndDate(property: PropertyInput): Date {
    const mortgage = property.mortgage

    if (
      !mortgage ||
      !mortgage.mortgage_term_end_month ||
      !mortgage.mortgage_term_end_year
    ) {
      throw new Error(
        'getMortgageTermEndDate should only be used for properties with a mortgage',
      )
    }

    return new Date(
      mortgage.mortgage_term_end_year,
      mortgage.mortgage_term_end_month - 1,
      1,
    )
  }
}

const compassReportService = new CompassReportService()

export default compassReportService
