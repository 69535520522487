import './CompleteRegistrationPopUp.scss'

import { useMutation, useQuery } from '@tanstack/react-query'
import React, { CSSProperties, useState } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useCurrentCompanyOrFail from '../../../hooks/useCurrentCompanyOrFail'
import { useInvalidateOnboardingChecklistQuery } from '../../../hooks/useInvalidateOnboardingChecklistQuery'
import useNotyf from '../../../hooks/useNotyf'
import addressService from '../../../lib/services/addressService'
import companyExpertiseAreaService from '../../../lib/services/companyExpertiseAreaService'
import companyService from '../../../lib/services/companyService'
import { selectExpertiseAreasByCompanyTypeIri } from '../../../redux/slices/commonData'
import { completeCompanyRegistration } from '../../../redux/slices/session'
import ActionButton from '../../misc/ActionButton'
import CompanyServiceDetails from './steps/1_CompanyServiceDetails/CompanyServiceDetails'
import CompanyServiceDetailsHint from './steps/1_CompanyServiceDetails/CompanyServiceDetailsHint'
import CompanyExpertiseAreas from './steps/2_CompanyExpertiseAreas/CompanyExpertiseAreas'
import CompanyExpertiseAreasHint from './steps/2_CompanyExpertiseAreas/CompanyExpertiseAreasHint'
import CompanyOfficeLocations from './steps/3_CompanyOfficeLocations/CompanyOfficeLocations'
import CompanyOfficeLocationsHint from './steps/3_CompanyOfficeLocations/CompanyOfficeLocationsHint'
import MeetingLink from './steps/4_MeetingLink/MeetingLink'
import MeetingLinkHint from './steps/4_MeetingLink/MeetingLinkHint'
import UploadLogo from './steps/5_UploadLogo/UploadLogo'
import UploadLogoHint from './steps/5_UploadLogo/UploadLogoHint'
import UploadVideo from './steps/6_UploadVideo/UploadVideo'
import UploadVideoHint from './steps/6_UploadVideo/UploadVideoHint'

interface Props {
  onHide: () => void
}

const CompleteRegistrationPopUp: React.FC<Props> = ({ onHide }) => {
  const dispatch = useAppDispatch()

  const currentCompany = useCurrentCompanyOrFail()

  const expertiseAreas = useAppSelector(
    selectExpertiseAreasByCompanyTypeIri(currentCompany.companyType['@id']),
  )

  const servicesQuery = useQuery({
    queryKey: ['services', currentCompany.id],
    queryFn: () => companyService.getCompanyServices(currentCompany.id),
  })

  const companyExpertiseAreasQuery = useQuery({
    queryKey: ['company-expertise-areas', currentCompany.id],
    queryFn: () => companyExpertiseAreaService.getCollectionForCurrentCompany(),
  })

  const addressesQuery = useQuery({
    queryKey: ['addresses', currentCompany.id],
    queryFn: () => addressService.getCurrentCompanyAddresses(),
  })

  const flowSteps = {
    companyServiceDetails: {
      name: 'List your services',
      render: () => <CompanyServiceDetails />,
      renderHelp: () => <CompanyServiceDetailsHint />,
      // Probably always true, since companies get default services and can't remove them
      isComplete: (): boolean =>
        servicesQuery.data !== undefined && servicesQuery.data.length > 0,
      isRequired: true,
      isNecessary: true,
    },
    companyExpertiseAreas: {
      name: 'Select your areas of expertise',
      render: () => <CompanyExpertiseAreas />,
      renderHelp: () => <CompanyExpertiseAreasHint />,
      isComplete: () =>
        companyExpertiseAreasQuery.data !== undefined &&
        companyExpertiseAreasQuery.data.length > 0,
      isRequired: false,
      isNecessary: expertiseAreas.length > 0,
    },
    companyOfficeLocations: {
      name: 'Enter your office locations',
      render: () => <CompanyOfficeLocations />,
      renderHelp: () => <CompanyOfficeLocationsHint />,
      isComplete: (): boolean =>
        addressesQuery.data !== undefined && addressesQuery.data.length > 0,
      // This is only for additional addresses, hence not required
      isRequired: true,
      isNecessary: true,
    },
    meetingLink: {
      name: 'Provide your meeting links',
      render: () => <MeetingLink />,
      renderHelp: () => <MeetingLinkHint />,
      isComplete: (): boolean =>
        !!currentCompany.scheduleClientCallUrl &&
        !!currentCompany.scheduleCompanyCallUrl,
      isRequired: false,
      isNecessary: true,
    },
    uploadLogo: {
      name: 'Upload your company logo',
      render: () => <UploadLogo />,
      renderHelp: () => <UploadLogoHint />,
      isComplete: (): boolean => !!currentCompany.logoUrl,
      isRequired: true,
      isNecessary: true,
    },
    uploadVideo: {
      name: 'Upload video about your firm',
      render: () => <UploadVideo />,
      renderHelp: () => <UploadVideoHint />,
      isComplete: (): boolean =>
        !!currentCompany.videoUrl || currentCompany.hasUnprocessedVideo,
      isRequired: false,
      isNecessary: true,
    },
  }

  type StepKey = keyof typeof flowSteps

  const [currentStepKey, setCurrentStepKey] = useState<StepKey>(
    Object.keys(flowSteps)[0] as StepKey,
  )
  const [visitedSteps, setVisitedSteps] = useState<string[]>([currentStepKey])

  function currentStepIsLastStep(): boolean {
    const flowStepKeys = Object.keys(flowSteps)

    return currentStepKey === flowStepKeys[flowStepKeys.length - 1]
  }

  function goToNextStep(current: StepKey): void {
    const flowStepKeys = Object.keys(flowSteps) as StepKey[]

    const currentStepIndex = flowStepKeys.indexOf(current)

    const newStepKey = flowStepKeys[
      // Don't go past the last one
      Math.min(currentStepIndex + 1, flowStepKeys.length - 1)
    ] as StepKey

    if (!flowSteps[newStepKey].isNecessary) {
      // Skip this unnecessary step, and move forward again
      goToNextStep(newStepKey)

      return
    }

    goToStep(newStepKey)
  }

  function goToStep(key: StepKey) {
    if (!visitedSteps.includes(key)) {
      setVisitedSteps([...visitedSteps, key])
    }

    setCurrentStepKey(key)
  }

  function isReadyToComplete(): boolean {
    for (const step of Object.values(flowSteps)) {
      if (step.isRequired && !step.isComplete()) {
        return false
      }
    }

    return true
  }

  function complete() {
    completeRegistrationMutation.mutate()
  }

  const notyf = useNotyf()

  const invalidateOnboardingChecklistQuery =
    useInvalidateOnboardingChecklistQuery()

  const completeRegistrationMutation = useMutation({
    mutationFn: () => companyService.completeRegistration(currentCompany.id),
    onSuccess: async () => {
      dispatch(completeCompanyRegistration())
      await invalidateOnboardingChecklistQuery()
      notyf.success('Registration successfully completed')
    },
    onError: () => {
      notyf.error('There was a problem completing your registration')
    },
  })

  if (currentCompany.isRegistrationComplete) {
    return null
  }

  const isNavigationAllowed =
    !flowSteps[currentStepKey].isRequired ||
    flowSteps[currentStepKey].isComplete()

  return (
    <Modal
      show
      centered
      size="xl"
      onHide={onHide}
      id="complete-registration-modal"
    >
      <Modal.Body className="p-0">
        <div className="d-flex align-items-stretch flex-grow-1">
          <div
            id="complete-registration-modal-hint-sidebar"
            className="fs-4 text-bg-dark p-4"
          >
            {flowSteps[currentStepKey].renderHelp()}
          </div>

          <div className="flex-grow-1 p-5 pb-4 text-center d-flex flex-column justify-content-between">
            <div className="mb-3 flex-grow-1">
              {flowSteps[currentStepKey].render()}
            </div>

            {/* Flow step navigation */}
            <div className="d-flex flex-column align-items-center">
              <div
                id="complete-registration-modal-steps-indicator"
                style={
                  {
                    '--number-of-columns': Object.values(flowSteps).filter(
                      (step) => step.isNecessary,
                    ).length,
                  } as CSSProperties
                }
                className="mb-3"
              >
                {(Object.keys(flowSteps) as StepKey[]).map(
                  (stepKey: StepKey) => {
                    if (!flowSteps[stepKey].isNecessary) {
                      return null
                    }

                    return (
                      <OverlayTrigger
                        key={stepKey}
                        overlay={<Tooltip>{flowSteps[stepKey].name}</Tooltip>}
                      >
                        {/* The clickable area, mostly invisible. Bigger than the
                        visible bit as otherwise the click/touch target is too small */}
                        <button
                          type="button"
                          onClick={() => {
                            if (isNavigationAllowed) {
                              goToStep(stepKey)
                            } else {
                              notyf.error(
                                'Please complete this step before moving to another',
                              )
                            }
                          }}
                        >
                          {/* The visual bit */}
                          <span
                            className={
                              stepKey === currentStepKey ? 'bg-primary' : ''
                            }
                          ></span>
                        </button>
                      </OverlayTrigger>
                    )
                  },
                )}
              </div>

              {!currentStepIsLastStep() && (
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => goToNextStep(currentStepKey)}
                  disabled={!isNavigationAllowed}
                >
                  Next
                </Button>
              )}

              {currentStepIsLastStep() && (
                <ActionButton
                  variant="success"
                  size="lg"
                  onClick={complete}
                  disabled={!isReadyToComplete()}
                  isProcessing={completeRegistrationMutation.isPending}
                >
                  Finish
                </ActionButton>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CompleteRegistrationPopUp
