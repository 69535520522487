import { CompanyExpertiseAreaCollectionItem } from '../../types/responses/companies'
import apiService from './apiService'

interface PostParams {
  company: IRI
  expertiseArea: IRI
}

class CompanyExpertiseAreaService {
  private endpoint = '/v1/company-expertise-areas'

  public async getCollectionForCurrentCompany(): Promise<CompanyExpertiseAreaCollectionItem> {
    const response = await apiService.get(this.endpoint)

    return response.data['hydra:member']
  }

  public async post(companyId: number, expertiseAreaId: number) {
    const params: PostParams = {
      company: `/v1/companies/${companyId}`,
      expertiseArea: `/v1/expertise-areas/${expertiseAreaId}`,
    }

    const response = await apiService.post(this.endpoint, params)

    return response.data
  }

  public async delete(comanyExpertiseAreaId: number) {
    const response = await apiService.delete(
      `${this.endpoint}/${comanyExpertiseAreaId}`,
    )

    return response.data
  }
}

const companyExpertiseAreaService = new CompanyExpertiseAreaService()

export default companyExpertiseAreaService
