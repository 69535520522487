import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@rq-ratings/pro-solid-svg-icons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'

import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import addressService from '../../../../../lib/services/addressService'
import CompanyAddressRow, {
  AddressFormValues,
} from './components/CompanyAddressRow'

const CompanyOfficeLocations: React.FC = () => {
  const [newAddress, setNewAddress] = useState<AddressFormValues | null>(null)

  const currentCompany = useCurrentCompanyOrFail()

  const queryClient = useQueryClient()

  const addressesQuery = useQuery({
    queryKey: ['addresses', currentCompany.id],
    queryFn: () => addressService.getCurrentCompanyAddresses(),
  })

  useEffect(() => {
    if (addressesQuery.data !== undefined && addressesQuery.data.length === 0) {
      setNewAddress({
        street: '',
        county: '',
        city: '',
        postcode: '',
        telephone: '',
      })
    }
  }, [addressesQuery.data])

  function renderInner() {
    if (addressesQuery.isLoading || addressesQuery.data === undefined) {
      return (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      )
    }

    const mainAddress = addressesQuery.data.find(
      (address) => address.isMainAddress,
    )

    const otherAddresses = addressesQuery.data.filter(
      (address) => !address.isMainAddress,
    )

    const orderedAddresses = mainAddress
      ? [mainAddress, ...otherAddresses]
      : otherAddresses

    return (
      <div className="d-flex flex-column gap-3 pb-3">
        <div
          className="d-flex flex-column gap-3 overflow-auto"
          style={{ maxHeight: '21rem' }}
        >
          <h3 className="m-0">Your headquarters</h3>

          {orderedAddresses.map((address, i) => {
            return (
              <>
                {i === 1 && <h3>Other locations:</h3>}

                <CompanyAddressRow
                  key={address.id}
                  address={address}
                  // First one is always the main one
                  isMainAddress={i === 0}
                  allowDelete={true}
                  isEditingByDefault={false}
                  onSave={() => {
                    queryClient.invalidateQueries({
                      queryKey: ['addresses'],
                    })
                  }}
                  onDelete={() => {
                    queryClient.invalidateQueries({
                      queryKey: ['addresses'],
                    })
                  }}
                />
              </>
            )
          })}

          {newAddress !== null && (
            <CompanyAddressRow
              address={newAddress}
              // This is the only address, must be the main one
              isMainAddress={orderedAddresses.length === 0}
              allowDelete={orderedAddresses.length > 0}
              isEditingByDefault={true}
              onSave={() => {
                queryClient.invalidateQueries({
                  queryKey: ['addresses'],
                })

                setNewAddress(null)
              }}
              onDelete={() => setNewAddress(null)}
            />
          )}
        </div>

        <div className="d-flex justify-content-center mt-3">
          <Button
            onClick={() =>
              setNewAddress({
                street: '',
                county: '',
                city: '',
                postcode: '',
                telephone: '',
              })
            }
            disabled={!!newAddress}
            size="sm"
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Add another location
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="pop-in h-100 d-flex flex-column align-items-stretch">
      <h2 className="h1 mb-5">Where are your firm's office locations?</h2>

      {renderInner()}
    </div>
  )
}

export default CompanyOfficeLocations
