import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import companyExpertiseAreaService from '../../../../../../lib/services/companyExpertiseAreaService'
import ActionButton from '../../../../../misc/ActionButton'

interface Props {
  /**
   * The ID of the joining record between Company and ExpertiseArea (if one exists)
   */
  comanyExpertiseAreaId?: number
  expertiseAreaId: number
  expertiseAreaName: string
  companyId: number
  onChangeSaved: () => void
}

const CompanyExpertiseAreaToggleButton: React.FC<Props> = ({
  comanyExpertiseAreaId,
  expertiseAreaId,
  expertiseAreaName,
  companyId,
  onChangeSaved,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(
    comanyExpertiseAreaId !== undefined,
  )

  // Watch for changes
  useEffect(() => {
    setIsSelected(comanyExpertiseAreaId !== undefined)
  }, [comanyExpertiseAreaId])

  const postMutation = useMutation({
    mutationFn: async () => {
      setIsSelected(true)
      return companyExpertiseAreaService.post(companyId, expertiseAreaId)
    },
    onSuccess: () => {
      onChangeSaved()
    },
    onError: () => {
      // Put this back how we found it before the mutation started
      setIsSelected(false)
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async () => {
      if (comanyExpertiseAreaId === undefined) {
        return
      }

      setIsSelected(false)
      return companyExpertiseAreaService.delete(comanyExpertiseAreaId)
    },
    onSuccess: () => {
      onChangeSaved()
    },
    onError: () => {
      // Put this back how we found it before the mutation started
      setIsSelected(true)
    },
  })

  // I.e. the prop passed doesn't reflect what we now think the state should be
  // which will be because we're waiting to re-query in the parent component
  const isWaitingForSync = (comanyExpertiseAreaId !== undefined) !== isSelected

  const tooltipText = isSelected
    ? `Deselect ${expertiseAreaName}`
    : `Add ${expertiseAreaName}`

  return (
    // The whole name doesn't always fit in the button, so show a tooltip
    <OverlayTrigger placement="auto" overlay={<Tooltip>{tooltipText}</Tooltip>}>
      <ActionButton
        variant={isSelected ? 'primary' : 'outline-secondary'}
        size="lg"
        className="fs-5"
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        isProcessing={
          postMutation.isPending || deleteMutation.isPending || isWaitingForSync
        }
        // Don't change text during processing
        isProcessingText={expertiseAreaName}
        aria-title={tooltipText}
        onClick={(): void => {
          if (isSelected) {
            deleteMutation.mutate()
          } else {
            postMutation.mutate()
          }
        }}
      >
        {expertiseAreaName}
      </ActionButton>
    </OverlayTrigger>
  )
}

export default CompanyExpertiseAreaToggleButton
