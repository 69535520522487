import React from 'react'

const CompanyExpertiseAreasHint: React.FC = () => {
  return (
    <div className="pop-in">
      <p>Let us know your firm's areas of expertise.</p>
      <p>Please select all that apply.</p>
    </div>
  )
}

export default CompanyExpertiseAreasHint
