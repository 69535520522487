import compassPersonalAssetsService from '../../../lib/services/compass/compassPersonalAssetsService'
import { CompassScores } from '../../../types/responses/compass'
import { CompassInput } from '../../flows/CompassFlow/utils/types'

// TODO: Replace number literals with named constants
export function defaultServiceAreaIds(): number[] {
  return [5, 6, 8, 10, 1001, 1009, 1017, 1030]
}

export type ServiceAreaIds = Record<number, number>

export function calculateServiceAreaIds(
  input: CompassInput,
  scores: CompassScores,
): ServiceAreaIds {
  const serviceAreaIds: ServiceAreaIds = {}

  if (compassPersonalAssetsService.hasMortgage(input.personalAssets)) {
    // TODO: Replace number literal with named constant
    serviceAreaIds[8] = 1
  }

  const feedbackItems: string[] = scores.feedback.map(
    (feedbackItem): string => {
      return feedbackItem.name
    },
  )

  if (scores.organisedOptimised?.rules_true) {
    feedbackItems.push(...scores.organisedOptimised.rules_true)
  }

  feedbackItems.forEach((feedbackItem) => {
    serviceAreaMapping.forEach((mapping) => {
      if (
        ('keyword' in mapping && mapping.keyword === feedbackItem) ||
        ('keywordRegex' in mapping && mapping.keywordRegex?.test(feedbackItem))
      ) {
        mapping.serviceAreaIds.forEach((id) => {
          serviceAreaIds[id] = (serviceAreaIds[id] || 0) + 1
        })
      }
    })
  })

  return serviceAreaIds
}

export function isServiceAreaIdsEmpty(serviceAreaIds: ServiceAreaIds): boolean {
  return Object.keys(serviceAreaIds).length === 0
}

interface ServiceAreaMapping {
  keyword?: string
  keywordRegex?: RegExp
  serviceAreaIds: number[]
}

// TODO
// - Replace number literals with named constants
// - Replace string literals in keyword with named constants
const serviceAreaMapping: ServiceAreaMapping[] = [
  { keyword: 'HasNotEnoughMoneySetAside', serviceAreaIds: [6] },
  { keyword: 'HasNegativeCashflow', serviceAreaIds: [6] },
  { keyword: 'HasNoFamilyLifeInsurance', serviceAreaIds: [6] },
  { keyword: 'HasNoWill', serviceAreaIds: [1030] },
  { keyword: 'HasNoKeyPersonInsurance', serviceAreaIds: [6] },
  { keyword: 'NoSelectedShareholderProtection', serviceAreaIds: [6] },
  { keyword: 'NoSelectedShareholderAgreement', serviceAreaIds: [1049] },
  { keyword: 'NoSelectedCrossOptionAgreement', serviceAreaIds: [1049] },
  { keyword: 'HighGrossAnnualIncome', serviceAreaIds: [6] },
  { keyword: 'HighGrossAnnualIncomePartner', serviceAreaIds: [6] },
  { keyword: 'IncomeTaxFeedback', serviceAreaIds: [6] },
  { keyword: 'HighRelativeExpenditures', serviceAreaIds: [6] },
  { keyword: 'VeryHighRelativeExpenditures', serviceAreaIds: [6] },
  { keyword: 'MortgageInterestEndsSoon', serviceAreaIds: [8] },
  { keyword: 'MortgageInterestEndsVerySoon', serviceAreaIds: [8] },
  { keyword: 'IsUnsureOrHasNoProtection', serviceAreaIds: [6] },
  { keyword: 'LifeTimeAllowanceBreach', serviceAreaIds: [6] },
  { keyword: 'LifeTimeAllowanceBreachPartner', serviceAreaIds: [6] },
  { keyword: 'HasTooMuchCash', serviceAreaIds: [6] },
  { keyword: 'AnnualAllowanceBreach', serviceAreaIds: [6] },
  { keyword: 'ShouldReviewPensionOrInvestment', serviceAreaIds: [6] },
  { keyword: 'HasConcernRunningOut', serviceAreaIds: [6] },
  { keyword: 'HasConcernPension', serviceAreaIds: [6] },
  { keyword: 'HasConcernInvestment', serviceAreaIds: [6] },
  { keyword: 'LowFinancialWellbeing', serviceAreaIds: [6] },
  { keyword: 'HighRelativeExpenditures', serviceAreaIds: [6] },
  { keyword: 'HigherRelativeExpenditures', serviceAreaIds: [6] },
  { keyword: 'VeryHighRelativeExpenditures', serviceAreaIds: [6] },
  { keyword: 'HighestRelativeExpenditures', serviceAreaIds: [6] },
  { keyword: 'HasNoKeyPersonInsurance', serviceAreaIds: [6] },
  { keyword: 'HasNoSelectedShareholderProtection', serviceAreaIds: [6] },
  { keyword: 'HasNoSelectedCrossOptionAgreement', serviceAreaIds: [6] },
  { keywordRegex: /^(.+)-SellSharesConfidence.*$/, serviceAreaIds: [1017, 6] },
  { keywordRegex: /^(.+)-SellSharesBizPlanning.*$/, serviceAreaIds: [1017, 6] },
  {
    keywordRegex: /^(.+)-SellSharesPersonalPlanning.*$/,
    serviceAreaIds: [1017, 6],
  },
]
