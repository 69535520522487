import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@rq-ratings/pro-solid-svg-icons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'

import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import companyService from '../../../../../lib/services/companyService'
import { CompanyServiceItem } from '../../../../../types/responses/companies'
import CompanyServiceDetailsRow, {
  ServiceFormValues,
} from './components/CompanyServiceDetailsRow'

const CompanyServiceDetails: React.FC = () => {
  const currentCompany = useCurrentCompanyOrFail()
  const [newService, setNewService] = useState<ServiceFormValues | null>(null)

  const queryClient = useQueryClient()

  const servicesQuery = useQuery({
    queryKey: ['services', currentCompany.id],
    queryFn: () => companyService.getCompanyServices(currentCompany.id),
  })

  if (servicesQuery.isLoading || servicesQuery.data === undefined) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <Spinner />
      </div>
    )
  }

  const services = servicesQuery.data.map(
    (service: CompanyServiceItem): ServiceFormValues => ({
      id: service.id,
      company: currentCompany['@id'],
      serviceArea: service.serviceArea,
      isStandaloneService: service.isStandaloneService,
      notes: service.notes ?? '',
      serviceType:
        service.serviceType === undefined
          ? undefined
          : service.serviceType['@id'],
    }),
  )

  return (
    <div className="pop-in">
      <h2 className="h1 mb-5">What services does your firm offer?</h2>

      <div
        className="d-flex flex-column gap-3 align-items-stretch overflow-auto"
        style={{ maxHeight: '22rem' }}
      >
        {services.map((service, i) => (
          <CompanyServiceDetailsRow
            key={`${i}-${service.id}`}
            isEditingByDefault={false}
            rowNumber={i}
            service={service}
            onSave={() => {}}
            onDelete={() => {
              queryClient.invalidateQueries({
                queryKey: ['services'],
              })
            }}
          />
        ))}

        {newService && (
          <CompanyServiceDetailsRow
            key="new"
            isEditingByDefault={true}
            rowNumber={-1}
            service={newService}
            // We're done with the new one now - it's in the collection
            onSave={async () => {
              await queryClient.invalidateQueries({
                queryKey: ['services'],
              })

              setNewService(null)
            }}
            onDelete={() => setNewService(null)}
          />
        )}
      </div>

      <div className="d-flex justify-content-center mt-3">
        <Button
          onClick={() =>
            setNewService({
              id: undefined,
              company: currentCompany['@id'],
              serviceArea: undefined,
              isStandaloneService: false,
              notes: '',
              serviceType: undefined,
            })
          }
          disabled={!!newService}
          size="sm"
        >
          <FontAwesomeIcon icon={faAdd} className="me-2" />
          Add another service
        </Button>
      </div>
    </div>
  )
}

export default CompanyServiceDetails
