import { selectCompanyTypeByCode } from '../redux/slices/commonData'
import useAppSelector from './useAppSelector'
import useCurrentCompanyOrFail from './useCurrentCompanyOrFail'

// Only use this hook in places where you're sure that the user is authenticated
// and has a company assigned.
export function useCompanyType() {
  const currentCompany = useCurrentCompanyOrFail()

  return useAppSelector(
    selectCompanyTypeByCode(currentCompany.companyType.code),
  )
}
