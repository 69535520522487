import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, ReactElement, ReactNode } from 'react'
import { Alert, Card } from 'react-bootstrap'
import { Variant } from 'react-bootstrap/types'

import { getOrFail } from '../../../lib/helpers/helperFunctions'
import CardHeading from '../../typography/CardHeading'

interface Props {
  header: string | ReactNode
  message: string | ReactNode
  variant: Variant
  wrapper?: ReactElement
}

const variantIconMapping: Record<Variant, IconDefinition> = {
  primary: faCheckCircle,
  secondary: faTimesCircle,
  success: faCheckCircle,
  danger: faExclamationCircle,
  warning: faExclamationCircle,
  info: faInfoCircle,
  dark: faTimesCircle,
  light: faCheckCircle,
}

const CardPartial: FC<Props> = ({
  header,
  message,
  variant,
  wrapper = <></>,
}) => {
  const icon = getOrFail(variantIconMapping[variant])

  const cardComponent = (
    <Card>
      <Card.Body>
        <CardHeading className="mb-4">{header}</CardHeading>
        <Alert variant={variant}>
          <div className="alert-icon my-auto">
            <FontAwesomeIcon icon={icon} fixedWidth size="lg" />
          </div>
          <div className="alert-message">{message}</div>
        </Alert>
      </Card.Body>
    </Card>
  )

  return wrapper
    ? React.cloneElement(wrapper, {}, cardComponent)
    : cardComponent
}

export default CardPartial
