import React from 'react'

const UploadVideoHint: React.FC = () => {
  return (
    <div className="pop-in">
      <p>
        Please share a video introducing you and your firm that can be added to
        your RQ profile.
      </p>

      <p>
        We suggest you keep the video short (less than two minutes) and you
        cover:
      </p>

      <ul>
        <li>You / your firm's 'why'</li>
        <li>The sorts of clients you serve and how you work with them</li>
        <li>How you work / would like to work with them and their clients</li>
      </ul>

      <p>
        Finally, be honest and be you - you want to give a sense of what it will
        be like to collaborate with your firm! If you would like advice
        producing your firm, the RQ team would be happy to help.
      </p>
    </div>
  )
}

export default UploadVideoHint
