import { FEEDBACK_TYPE } from '../../../components/flows/CompassFlow/utils/constants'
import { CategoryFeedback } from '../../../components/flows/CompassFlow/utils/types'
import {
  AssetOutlook,
  CompassFeedback,
  NewAssetOutlook,
} from '../../../types/responses/compass'
import { THEME_PALETTE } from '../../constants'

class CompassFeedbackService {
  getCategorisedFeedback(feedbackList: CompassFeedback[]): CategoryFeedback[] {
    const categorisedFeedback: CategoryFeedback[] = []

    feedbackList.forEach((feedback) => {
      const category = categorisedFeedback.find(
        ({ category }) => category === feedback.category,
      )

      // Push to category if it already exists
      if (category) {
        category.feedbackList.push(feedback)
        return
      }

      // Otherwise, create new category if it doesn't exist
      categorisedFeedback.push({
        category: feedback.category,
        feedbackList: [feedback],
      })

      // Sort categories alphabetically
      return categorisedFeedback.sort((categoryA, categoryB) =>
        categoryA.category.localeCompare(categoryB.category),
      )
    })

    // Only include feedback categories that have an 'Action' feedback
    return categorisedFeedback.filter((categorisedFeedback) => {
      const actions = this.getActions(categorisedFeedback.feedbackList)

      return actions.length > 0
    })
  }

  getActions(feedback: CompassFeedback[]): CompassFeedback[] {
    return feedback.filter(({ type }) => type === FEEDBACK_TYPE.action)
  }

  getFinancialHealthColor(score: number): string {
    const SCORE_THRESHOLDS = {
      high: 76,
      medium: 41,
    }

    if (score >= SCORE_THRESHOLDS.high) {
      return THEME_PALETTE.success
    }

    if (score >= SCORE_THRESHOLDS.medium) {
      return THEME_PALETTE.warning
    }

    return THEME_PALETTE.danger
  }

  // For older snapshots, the `outlook` object only has a `total` field.
  isOutlookCategorized(
    outlook: AssetOutlook | NewAssetOutlook,
  ): outlook is NewAssetOutlook {
    return !!(outlook.total && outlook.components)
  }
}

const compassFeedbackService = new CompassFeedbackService()

export default compassFeedbackService
