import { FieldProps } from 'formik'
import React from 'react'

import {
  FCA_SERVICE_TYPE_IDS,
  FCA_SERVICE_TYPE_NAMES,
} from '../../../../../../../lib/constants'
import Select from '../../../../../../select/Select'

interface Props extends FieldProps {
  className: string
}

type FcaServiceTypeKeys = keyof typeof FCA_SERVICE_TYPE_NAMES &
  keyof typeof FCA_SERVICE_TYPE_IDS

const SelectServiceTypeField: React.FC<Props> = ({
  field,
  form,
  className,
}) => {
  const options = (
    Object.keys(FCA_SERVICE_TYPE_IDS) as FcaServiceTypeKeys[]
  ).map((key: FcaServiceTypeKeys) => ({
    value: `/v1/fca-service-types/${FCA_SERVICE_TYPE_IDS[key]}`,
    label: FCA_SERVICE_TYPE_NAMES[key],
  }))

  return (
    <Select
      className={className}
      options={options}
      name={field.name}
      value={options.find((option) => option?.value === field?.value)}
      onChange={(option) => form.setFieldValue(field.name, option?.value)}
      onBlur={field.onBlur}
      // important because this opens inside an overflow: hidden
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  )
}

export default SelectServiceTypeField
